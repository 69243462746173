export default {
  'notifications.OrderPaid': 'Commande payée',
  'notifications.UserLogged': 'Utilisateur connecté',
  'notifications.list': 'Liste',
  'notifications.TopicFinished': 'Sujet terminé',
  'notifications.CourseAssigned': 'Cours assigné',
  'notifications.PermissionRoleRemoved': 'Rôle de permission supprimé',
  'notifications.PermissionRoleChanged': 'Rôle de permission modifié',
  'notifications.CartOrderPaid': 'Commande de panier payée',
  'notifications.CourseAccessFinished': 'Accès au cours terminé',
  'notifications.Logout': 'Déconnexion',
  'notifications.UserRemovedFromGroup': 'Utilisateur supprimé du groupe',
  'notifications.UserAddedToGroup': 'Utilisateur ajouté au groupe',
  'notifications.CoursedPublished': 'Cours publié',
  'notifications.ResetPassword': 'Réinitialiser le mot de passe',
  'notifications.CourseAccessStarted': 'Accès au cours commencé',
  'notifications.AccountRegistered': 'Compte enregistré',
  'notifications.ForgotPassword': 'Mot de passe oublié',
  'notifications.Login': 'Connexion',
  'notifications.TopicTypeChanged': 'Type de sujet modifié',
  'notifications.AccountDeleted': 'Compte supprimé',
  'notifications.AccountBlocked': 'Compte bloqué',
  'notifications.CourseFinished': 'Cours terminé',
  'notifications.PdfCreatedEvent': 'PDF créé',
  'notifications.CourseTutorUnassigned': 'Tuteur de cours non assigné',
  'notifications.CourseUnassigned': 'Cours non assigné',
  'notifications.AccountMustBeEnableByAdmin': "Le compte doit être activé par l'administrateur",
  'notifications.SettingPackageConfigUpdated': 'Configuration de package de paramètres mise à jour',
  'notifications.CourseTutorAssigned': 'Tuteur de cours assigné',
  'notifications.AccountConfirmed': 'Compte confirmé',
};
