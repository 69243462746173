import { useModel } from 'umi';

import './index.css';

export default () => {
  const { initialState } = useModel('@@initialState');

  const backgroundColor = initialState?.publicConfig?.global?.contentBackgroundColor ?? '#f0f2f5';

  return (
    <footer className="ant-layout-footer" style={{ padding: 0, backgroundColor }}>
      <div className="ant-pro-global-footer" />
    </footer>
  );
};
