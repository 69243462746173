export default {
  'fabric.preview': 'Podgląd PDF bez wartości zmiennych',
  'fabric.remove': 'Usuń obiekt',
  'fabric.text_options': 'Opcje tekstowe',
  'fabric.image_options': 'Opcje obrazu',
  'fabric.line_options': 'Opcje lini',
  'fabric.object_options': 'Opcje obiektu',
  'fabric.stroke_color': 'Kolor kreski',
  'fabric.background_color': 'Kolor tła',
  'fabric.stroke_width': 'Szerokość kreski',
  'fabric.line_color': 'Kolor lini',
  'fabric.font_family': 'Rodzina fontu',
  'fabric.font_size': 'Rozmiar fontu',
  'fabric.text_align': 'Wyrównanie tekstu',
  'fabric.line_height': 'Wysokość lini',
  'fabric.bold': 'Pogrubienie',
  'fabric.italic': 'Kursywa',
  'fabric.underline': 'Podkreślenie',
  'fabric.linethrough': 'Przecięcie',
  'fabric.overline': 'Linia nad',
  'fabric.text_color': 'Kolor tekstu',
  'fabric.text_background': 'Kolor tła tekstu',
  'fabric.add_circle': 'Dodaj okrąg',
  'fabric.add_rectangle': 'Dodaj prostokąt',
  'fabric.add_line': 'Dodaj linie',
  'fabric.add_image': 'Dodaj obrazek',
  'fabric.add_object': 'Dodaj obiekt',
  'fabric.add_text': 'Dodaj tekst',
  'fabric.add_textbox': 'Dodaj pole tekstowe',
  'fabric.clear': 'Wyczyść edytor',
  'fabric.editor_options': 'Opcje edytora',
  'fabric.add': 'Dodaj ',
  'fabric.editor_background_color': 'Kolor tła edytora',
};
