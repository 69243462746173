// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/vercel/path0/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/vercel/path0/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "access": "loggedOut",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__login' */'/vercel/path0/src/pages/User/login'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "reset",
                "path": "/user/reset-password",
                "access": "loggedOut",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__reset' */'/vercel/path0/src/pages/User/reset'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/courses",
        "name": "Courses",
        "icon": "fire",
        "access": "coursesPermission",
        "routes": [
          {
            "path": "/courses",
            "redirect": "/courses/list",
            "access": "coursesPermission",
            "exact": true
          },
          {
            "path": "/courses/list",
            "name": "List",
            "icon": "fire",
            "access": "courseListPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses' */'/vercel/path0/src/pages/Courses'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/courses/list/:course",
            "name": "Form",
            "icon": "book",
            "access": "courseDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses__form' */'/vercel/path0/src/pages/Courses/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/courses/list/:course/:tab",
            "name": "Course Form",
            "icon": "book",
            "access": "courseDetailsPermission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Courses__form' */'/vercel/path0/src/pages/Courses/form'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": false
          }
        ]
      },
      {
        "path": "/my-profile",
        "name": "My Profile",
        "access": "userProfilePermission",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/my-profile",
            "redirect": "/my-profile/general",
            "access": "userProfilePermission",
            "exact": true
          }
        ]
      },
      {
        "access": "dashboardPermission",
        "path": "/",
        "redirect": "/courses/list",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/vercel/path0/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
