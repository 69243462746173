// TODO add all packages
enum PACKAGES {
  Api = 'escolalms/api',
  AssignWithoutAccount = 'escolalms/assign-without-account',
  Auth = 'escolalms/auth',
  BookmarksNotes = 'escolalms/bookmarks_notes',
  Cart = 'escolalms/cart',
  Cmi5 = 'escolalms/cmi5',
  Core = 'escolalms/core',
  CourseAccess = 'escolalms/course-access',
  Courses = 'escolalms/courses',
  CoursesImportExport = 'escolalms/courses-import-export',
  CsvUsers = 'escolalms/csv-users',
  Files = 'escolalms/files',
  HeadlessH5p = 'escolalms/headless-h5p',
  Images = 'escolalms/images',
  Invoices = 'escolalms/invoices',
  Jitsi = 'escolalms/jitsi',
  Lrs = 'escolalms/lrs',
  Mailerlite = 'escolalms/mailerlite',
  Mattermost = 'escolalms/mattermost',
  ModelFields = 'escolalms/model-fields',
  Notifications = 'escolalms/notifications',
  Pages = 'escolalms/pages',
  PCGAttendances = 'escolalms/pcg-attendances',
  PCGGrades = 'escolalms/pcg-grades',
  PCGIntegration = 'escolalms/pcg-integration',
  PencilSpaces = 'escolalms/pencil-spaces',
  Permissions = 'escolalms/permissions',
  Questionnaire = 'escolalms/questionnaire',
  Reports = 'escolalms/reports',
  Settings = 'escolalms/settings',
  StationaryEvents = 'escolalms/stationary-events',
  Tags = 'escolalms/tags',
  Tasks = 'escolalms/tasks',
  Templates = 'escolalms/templates',
  TemplatesEmail = 'escolalms/templates-email',
  TemplatesPdf = 'escolalms/templates-pdf',
  TemplatesSms = 'escolalms/templates-sms',
  TopicTypeGift = 'escolalms/topic-type-gift',
  TopicTypeProject = 'escolalms/topic-type-project',
  TopicTypes = 'escolalms/topic-types',
  Tracker = 'escolalms/tracker',
  Translations = 'escolalms/translations',
  Video = 'escolalms/video',
  Youtube = 'escolalms/youtube',
}

export default PACKAGES;
