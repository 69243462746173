export default {
  'notifications.OrderPaid': 'Zamówienie opłacone',
  'notifications.UserLogged': 'Użytkownik zalogowany',
  'notifications.list': 'Lista',
  'notifications.TopicFinished': 'Temat zakończony',
  'notifications.CourseAssigned': 'Kurs przypisany',
  'notifications.PermissionRoleRemoved': 'Uprawnienia usunięte',
  'notifications.PermissionRoleChanged': 'Uprawnienia zmienione',
  'notifications.CartOrderPaid': 'Zamówienie w koszyku opłacone',
  'notifications.CourseAccessFinished': 'Dostęp do kursu zakończony',
  'notifications.Logout': 'Wylogowanie',
  'notifications.UserRemovedFromGroup': 'Użytkownik usunięty z grupy',
  'notifications.UserAddedToGroup': 'Użytkownik dodany do grupy',
  'notifications.CoursedPublished': 'Kurs opublikowany',
  'notifications.ResetPassword': 'Reset hasła',
  'notifications.CourseAccessStarted': 'Kurs aktywowany',
  'notifications.AccountRegistered': 'Konto zarejestrowano',
  'notifications.ForgotPassword': 'Zapomniałeś hasła',
  'notifications.Login': 'Logowanie',
  'notifications.TopicTypeChanged': 'Typ tematu zmieniony',
  'notifications.AccountDeleted': 'Konto usunięte',
  'notifications.AccountBlocked': 'Konto zablokowane',
  'notifications.CourseFinished': 'Kurs ukończony',
  'notifications.PdfCreatedEvent': 'PDF stworzony',
  'notifications.CourseTutorUnassigned': 'Instruktor nieprzypisany',
  'notifications.CourseUnassigned': 'Kurs nieprzypisany',
  'notifications.AccountMustBeEnableByAdmin':
    'Konto oczekuje na zatwierdzenie przez administratora',
  'notifications.SettingPackageConfigUpdated': 'Zaktualizowano konfigurację pakietu',
  'notifications.CourseTutorAssigned': 'Instruktor przypisany',
  'notifications.AccountConfirmed': 'Konto potwierdzone',
};
