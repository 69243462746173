export default {
  'fabric.preview': 'Preview PDF without mock value',
  'fabric.remove': 'Remove object',
  'fabric.text_options': 'Text options',
  'fabric.image_options': 'Image options',
  'fabric.line_options': 'Line options',
  'fabric.object_options': 'Object options',
  'fabric.stroke_color': 'Stroke color',
  'fabric.background_color': 'Background color',
  'fabric.stroke_width': 'Stroke width',
  'fabric.line_color': 'Line color',
  'fabric.font_family': 'Font family',
  'fabric.font_size': 'Font size',
  'fabric.text_align': 'Text align',
  'fabric.line_height': 'Line height',
  'fabric.bold': 'Bold',
  'fabric.italic': 'Italic',
  'fabric.underline': 'Underline',
  'fabric.linethrough': 'Linethrough',
  'fabric.overline': 'Overline',
  'fabric.text_color': 'Text color',
  'fabric.text_background': 'Background text color',
  'fabric.add_circle': 'Add circle',
  'fabric.add_rectangle': 'Add rectangle',
  'fabric.add_line': 'Add line',
  'fabric.add_image': 'Add image',
  'fabric.add_object': 'Add object',
  'fabric.add_text': 'Add text',
  'fabric.add_textbox': 'Add text box',
  'fabric.clear': 'Clear editor',
  'fabric.editor_options': 'Editor options',
  'fabric.add': 'Add ',
  'fabric.editor_background_color': 'Editor background color',
};
