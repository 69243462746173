export default {
  'pages.layouts.userLayout.title':
    'Ant Design to najbardziej wpływowa specyfikacja projektowania stron internetowych w dzielnicy Xihu ',
  'pages.login.accountLogin.tab': 'Login',
  'pages.login.accountLogin.errorMessage': 'Nieprawidłowy login lub hasło',
  'pages.login.username.placeholder': 'Nazwa użytkownika',
  'pages.login.username.required': 'Wprowadź nazwę użytkownika!',
  'pages.login.password.placeholder': 'Hasło',
  'pages.login.password.required': 'Wprowadź hasło!',
  'pages.login.phoneLogin.tab': 'Kod z telefonu',
  'pages.login.phoneLogin.errorMessage': 'Wystąpił błąd podczas weryfikacji kodu',
  'pages.login.phoneNumber.placeholder': 'Numer telefonu',
  'pages.login.phoneNumber.required': 'Wprowadź numer telefonu!',
  'pages.login.phoneNumber.invalid': 'Numer telefonu jest nieprawidłowy!',
  'pages.login.captcha.placeholder': 'Kod weryfikacyjny',
  'pages.login.captcha.required': 'Wprowadź kod weryfikacyjny!',
  'pages.login.phoneLogin.getVerificationCode': 'Zdobądź kod',
  'pages.getCaptchaSecondText': 'sekund',
  'pages.login.rememberMe': 'Zapamiętaj mnie',
  'pages.login.forgotPassword': 'Zapomniałeś hasła?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Zaloguj z:',
  'pages.login.registerAccount': 'Zarejestruj konto',
  'pages.welcome.advancedComponent': 'Zaawansowany komponent',
  'pages.welcome.link': 'Witaj',
  'pages.welcome.advancedLayout': 'Zaawansowany layout',
  'pages.welcome.alertMessage':
    'Wprowadzono na rynek szybsze i mocniejsze podzespoły o dużej wytrzymałości. ',
  'pages.welcome.content': 'Cześć, witaj w EscolaLMS!',
  'pages.admin.subPage.title': 'Ta strona może być przeglądana tylko przez Administratora',
  'pages.admin.subPage.alertMessage':
    'Umi ui jest już wydany, zapraszamy do korzystania z npm run ui, aby rozpocząć doświadczenie.',
  'pages.searchTable.createForm.newRule': 'Nowa reguła',
  'pages.searchTable.updateForm.ruleConfig': 'Konfiguracja reguł',
  'pages.searchTable.updateForm.basicConfig': 'Podstawowe informacje',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Nazwa reguły',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Wprowadź nazwę reguły!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Opis reguły',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Wprowadź co najmniej 5 znaków',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Wprowadź opis reguły z co najmniej pięcioma znakami!',
  'pages.searchTable.updateForm.ruleProps.title': 'Skonfiguruj właściwości',
  'pages.searchTable.updateForm.object': 'Kontroluj obiekt',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Szablon reguł',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Typ reguły',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Ustaw okres planowania',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Czas startu',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Wprowadź czas startu!',
  'pages.searchTable.titleDesc': 'Opis',
  'pages.searchTable.ruleName': 'Nazwa reguły jest obowiązkowa',
  'pages.searchTable.titleCallNo': 'Liczba zgłoszeń serwisowych',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'domyślny',
  'pages.searchTable.nameStatus.running': 'w ruchu',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'nieprawidłowy',
  'pages.searchTable.titleUpdatedAt': 'Ostatnio zaplanowane o',
  'pages.searchTable.exception': 'Wprowadź przyczynę wyjątku!',
  'pages.searchTable.titleOption': 'Opcje',
  'pages.searchTable.config': 'Konfiguracja',
  'pages.searchTable.subscribeAlert': 'Subskrybuj alerty',
  'pages.searchTable.title': 'Formularz zapytania',
  'pages.searchTable.new': 'Nowy',
  'pages.searchTable.chosen': 'Wybrany',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Całkowita liczba zgłoszeń serwisowych',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'seria usunięć',
  'pages.searchTable.batchApproval': 'seria potwierdzeń',
  'pages.files.content': 'Lista plików',
  'pages.files.filesBrowser': 'Aktualny folder to:',
};
