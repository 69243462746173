import PACKAGES from '@/consts/packages';
import PERMISSIONS from '@/consts/permissions';
import { isUserHavePermissions } from '@/services/escola-lms/permissions';

/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function (initialState: {
  currentUser: API.UserItem;
  config: API.Setting[];
  packages: Record<string, string>;
}) {
  const currentUser = initialState?.currentUser;

  const havePermissions = isUserHavePermissions(currentUser);
  const dashboardPermission = havePermissions(PERMISSIONS.CoreDashboardAccess);
  const havePermissionsInDashboard = (...permissions: PERMISSIONS[]) => {
    return dashboardPermission && havePermissions(...permissions);
  };

  // TODO implement minimal versions
  const havePackageInstalled = (
    packageName: PACKAGES,
    // ,minVersion: string = '0.0.0'
  ): boolean => {
    return Boolean(initialState?.packages?.[packageName]);
  };

  const haveSettingsInDashboard = (
    settingName: string,
    expectedValue: string | number | boolean,
  ): boolean => {
    const userRoles = currentUser?.roles || [];
    const settingData = initialState?.config?.find(({ key }) => key === settingName)?.data;
    if (Array.isArray(userRoles) && Array.isArray(settingData)) {
      return userRoles.every((role) => settingData.includes(role));
    }
    return settingData === expectedValue;
  };

  // Quiz reports
  const canAccessQuizReports =
    !haveSettingsInDashboard('hideInMenu-CoursesQuiz-reports', true) &&
    havePackageInstalled(PACKAGES.TopicTypeGift);

  const canAccessQuizReportsList =
    havePermissionsInDashboard(PERMISSIONS.QuizAttemptList) && canAccessQuizReports;

  const canAccessQuizReportsListSelf =
    havePermissionsInDashboard(PERMISSIONS.QuizAttemptListSelf) && canAccessQuizReports;

  // H5P
  const canAccessH5P =
    !haveSettingsInDashboard('hideInMenu-CoursesH5ps', true) &&
    havePackageInstalled(PACKAGES.HeadlessH5p);
  const canAccessH5PList = havePermissionsInDashboard(PERMISSIONS.H5PList) && canAccessH5P;
  const canAccessH5PAuthorList =
    havePermissionsInDashboard(PERMISSIONS.H5PAuthorList) && canAccessH5P;

  const canAccessH5PDetails = havePermissionsInDashboard(PERMISSIONS.H5PRead) && canAccessH5P;

  return {
    dashboardPermission: true,

    userProfilePermission: havePermissionsInDashboard(PERMISSIONS.UserReadSelf),

    analyticsPermission: havePermissionsInDashboard(
      PERMISSIONS.NotificationListAll,
      PERMISSIONS.ReportList,
      PERMISSIONS.TrackerList,
    ),

    configurationPermission: havePermissionsInDashboard(
      PERMISSIONS.FileList,
      PERMISSIONS.SettingsList,
    ),

    coursesPermission: true,
    // havePermissionsInDashboard(PERMISSIONS.CourseList, PERMISSIONS.H5PList) &&
    // !haveSettingsInDashboard('hideInMenu-Courses', true),

    courseListPermission: true,
    // havePermissionsInDashboard(PERMISSIONS.CourseList) &&
    // !haveSettingsInDashboard('hideInMenu-CoursesList', true),
    courseDetailsPermission: true, //havePermissionsInDashboard(PERMISSIONS.CourseRead),

    h5pListPermission: canAccessH5PList || canAccessH5PAuthorList,
    h5pDetailsPermission: canAccessH5PDetails,

    pageListPermission: havePermissionsInDashboard(PERMISSIONS.PageList),
    pageDetailsPermission: havePermissionsInDashboard(PERMISSIONS.PageRead),

    fileListPermission: havePermissionsInDashboard(PERMISSIONS.FileList),

    settingListPermission: havePermissionsInDashboard(PERMISSIONS.SettingsList),

    notificationListPermission: havePermissionsInDashboard(PERMISSIONS.NotificationListAll),

    reportListPermission:
      havePermissionsInDashboard(PERMISSIONS.ReportList) &&
      !haveSettingsInDashboard('hideInMenu-CoursesCategories', true),

    questionnaireListPermission:
      havePermissionsInDashboard(PERMISSIONS.QuestionnaireList) &&
      !haveSettingsInDashboard('hideInMenu-OtherQuestionnaire', true),
    questionnaireDetailPermission: havePermissionsInDashboard(PERMISSIONS.QuestionnaireRead),

    trackerListPermission: havePermissionsInDashboard(PERMISSIONS.TrackerList),

    loggedOut: !currentUser,

    taskListPermission: havePermissionsInDashboard(PERMISSIONS.TaskList),
    taskDetailsPermission: havePermissionsInDashboard(PERMISSIONS.TaskList),

    courseAccessListPermission: true,
    // havePermissionsInDashboard(PERMISSIONS.CourseAccessList) &&
    // !haveSettingsInDashboard('hideInMenu-CoursesAccess', true) &&
    // havePackageInstalled(PACKAGES.CourseAccess),

    consultationAccessListPermission:
      havePermissionsInDashboard(PERMISSIONS.ConsultationAccessList) &&
      !haveSettingsInDashboard('hideInMenu-OtherConsultation-access', true),

    coursesQuizReportsListPermission: true, //canAccessQuizReportsList || canAccessQuizReportsListSelf,

    teacherPermission:
      havePermissionsInDashboard(
        PERMISSIONS.PCGListSemesterSubjects,
        PERMISSIONS.PCGListSemesters,
        PERMISSIONS.PCGListAcademicYears,
      ) && !haveSettingsInDashboard('hideInMenu-Teacher', true),

    teacherSubjectListPermission:
      havePermissionsInDashboard(
        PERMISSIONS.PCGListSemesterSubjects,
        PERMISSIONS.PCGListSemesters,
        PERMISSIONS.PCGListAcademicYears,
      ) &&
      !haveSettingsInDashboard('hideInMenu-TeacherSubjects', true) &&
      havePackageInstalled(PACKAGES.PCGIntegration),

    teacherSubjectDetailsPermission:
      havePermissionsInDashboard(
        PERMISSIONS.TeacherListExamResult,
        PERMISSIONS.TeacherListGradeTerm,
        PERMISSIONS.TeacherListAttendance,
        PERMISSIONS.TeacherListGradeScale,
        PERMISSIONS.TeacherListFinalGrade,
        PERMISSIONS.TeacherSaveExamResult,
        PERMISSIONS.TeacherSaveAttendance,
        PERMISSIONS.TeacherSaveFinalGrade,
        PERMISSIONS.TeacherDeleteExamResult,
      ) &&
      !haveSettingsInDashboard('hideInMenu-TeacherSubjects', true) &&
      havePackageInstalled(PACKAGES.PCGIntegration) &&
      havePackageInstalled(PACKAGES.PCGGrades) &&
      havePackageInstalled(PACKAGES.PCGAttendances),
    tasksPermission: () => true,
  };
}
